import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { SearchInput } from "components/Filters/SearchInput";

import { useWorkersQuery } from "../infrastructure/useWorkersQuery";
import { TestAllWorkersButton } from "./common/TestAllWorkersButton";
import { WorkersTable } from "./common/WorkersTable";

export const InactiveWorkersTable = () => {
  const { data, isLoading } = useWorkersQuery();

  return (
    <Card variant="sawpe" id="inactive-workers">
      <CardHeader>
        <Stack w="full" direction="row" align="center" spacing={6} wrap="wrap">
          <Heading fontSize="lg">
            {t("Nieaktywne integracje enterprise")}
          </Heading>
          <TestAllWorkersButton />
          <Spacer />
          <SearchInput
            globalSearchParam="inactiveWorkersSearch"
            data-testid="inactive-workers-search"
          />
        </Stack>
      </CardHeader>
      <CardBody>
        <WorkersTable
          workers={data?.organizations.filter((org) => !org.active)}
          isLoading={isLoading}
          globalSearchParam="inactiveWorkersSearch"
        />
      </CardBody>
    </Card>
  );
};
