import { useParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

export const BlackListAlert = () => {
  const type = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  if (!["context_manager", "context_user"].includes(type!)) {
    return null;
  }

  return <BlackListAlertConnected />;
};

const BlackListAlertConnected = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useOrganizationByIdQuery(organizationId!);

  if (data?.organization.bannedDate) {
    return <Banned />;
  }

  if (!data?.organization.blackList) {
    return null;
  }

  return <WithCountDown date={data?.organization.blackList.deactivationDate} />;
};

const WithCountDown = ({ date }: { date: string }) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return isVisible ? (
    <Alert
      status="warning"
      left={8}
      position="fixed"
      maxW="calc(100vw - 78px)"
      zIndex="1"
      borderRadius="md"
      boxShadow="md"
    >
      <AlertIcon />
      <Box>
        <AlertTitle>{t("Uwaga!")}</AlertTitle>
        <AlertDescription>
          {t(
            "Jeśli nie opłacisz usługi w ciągu {{dayCount}}, stracisz dostęp do weryfikacji podpisów. Zadbaj o płatność, aby nie stracić dostępu do pełnych możliwości naszego serwisu.",
            {
              dayCount: relativeDays(date),
            }
          )}
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="absolute"
        right={2}
        top={2}
        onClick={onClose}
      />
    </Alert>
  ) : null;
};

const Banned = () => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return isVisible ? (
    <Alert
      status="error"
      left={8}
      position="fixed"
      maxW="calc(100vw - 78px)"
      zIndex="1"
      borderRadius="md"
      boxShadow="md"
    >
      <AlertIcon />
      <Box>
        <AlertTitle>{t("Uwaga!")}</AlertTitle>
        <AlertDescription>
          {t(
            "Straciłeś dostęp do weryfikacji podpisów z powodu nie uregulowania płatności w wyznaczonym terminie. Ureguluj zaległości aby odzyskać dostęp do pełnych możliwości naszego serwisu."
          )}
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="absolute"
        right={2}
        top={2}
        onClick={onClose}
      />
    </Alert>
  ) : null;
};

const relativeDays = (date: string) => {
  const diff = dayjs(date).diff(dayjs(), "day");

  if (diff === 0) {
    const diff = dayjs(date).diff(dayjs(), "hours");

    return `${diff} ${t("godzin")}`;
  }

  if (diff === 1) {
    return `1 ${t("dnia")}`;
  }

  return `${diff} dni`;
};
