import { t } from "i18next";

import { CheckboxGroup } from "components/Filters/CheckboxGroup";
import { FilterDrawer } from "components/Filters/FilterDrawer";

export const SystemEventsFiltersDrawer = () => {
  return (
    <FilterDrawer drawerTitle={t("Filtry")} trackedParams={["eventName"]}>
      <CheckboxGroup
        filterId="eventName"
        label={t("Kategorie eventów")}
        options={[
          ["tariff", "Pakiet"],
          ["user", "Użytkownik"],
          ["context", "Kontekst"],
          ["file", "Przesłano plik"],
          ["integrationKey", "Klucz integracji"],
          ["email", "E-mail"],
          ["apiKey", "Klucz API"],
          ["organization", "Podmiot"],
          ["notificationsProcess", "Powiadomienia"],
          ["verificationInterpretation", "Zapytanie o interpretację"],
          ["complaint", "Reklamacja"],
          ["changelog", "Changelog"],
          ["blackList", "Czarna lista"],
          ["workerIntegration", "Integracja workera"],
        ]}
      />
    </FilterDrawer>
  );
};
