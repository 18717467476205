import { Td } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IVerificationProcess } from "modules/verification/application/types/IVerificationProcess";

import { VerificationLink } from "./VerificationLink";

const columnHelper = createColumnHelper<IVerificationProcess>();

const columns = [
  columnHelper.accessor("createdAt", {
    header: () => t("Data"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("ip", {
    header: () => t("IP"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("verificationId", {
    header: () => t("Identyfikator weryfikacji"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="verification-link">
        <VerificationLink verificationId={info.getValue()} />
      </Td>
    ),
  }),
];

interface IProps {
  verificationProcesses: IVerificationProcess[];
  isLoading: boolean;
}

export const PendingVerificationsTable = ({
  verificationProcesses,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (verificationProcesses?.length === 0) {
    return <TableEmptyState title={t("Brak nieodczytanych weryfikacji")} />;
  }

  const sortedRecords = reverse(
    sortBy(verificationProcesses, function (process) {
      return new Date(process.createdAt);
    })
  );

  return <Table<IVerificationProcess> data={sortedRecords} columns={columns} />;
};
