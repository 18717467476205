import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Td,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import sortBy from "lodash/sortBy";

import { SearchInput } from "components/Filters/SearchInput";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { LinkCell } from "components/Table/LinkCell";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IOrganization } from "modules/organization/application/types/IOrganization";
import { useBlackListQuery } from "modules/organization/infrastructure/useBlackListQuery";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";

import { BlackListTable } from "./BlackListTable";
import { EditableComment } from "./EditableComment";
import { RelativeTime } from "./RelativeTime";
import { RemoveFromBlackListBtn } from "./RemoveFromBlackListBtn";

const columnHelper = createColumnHelper<IOrganization>();

const columns = [
  columnHelper.accessor("details.displayName", {
    id: "name",
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td data-testid="organization-name" whiteSpace="nowrap" maxW="250px">
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor(
    (row) => row.bannedDate || row.blackList?.deactivationDate,
    {
      id: "deactivationDate",
      header: () => t("Planowana data dezaktywacji"),
      cell: (info) => (
        <Td whiteSpace="normal">
          <FormatDate date={info.getValue()!} />
          {!info.row.original.blackList?.canceled && (
            <RelativeTime value={info.getValue()!} />
          )}
        </Td>
      ),
    }
  ),
  columnHelper.accessor("bannedDate", {
    id: "status",
    header: () => t("Status"),
    cell: (info) => (
      <Td whiteSpace="normal" data-testid="status">
        {info.getValue() ? (
          <Tag
            size="sm"
            variant="subtle"
            colorScheme="red"
            verticalAlign="middle"
          >
            {t("Zablokowano")}
          </Tag>
        ) : (
          <Tag
            size="sm"
            variant="subtle"
            colorScheme="orange"
            verticalAlign="middle"
          >
            {t("Oczekujący")}
          </Tag>
        )}
      </Td>
    ),
  }),
  columnHelper.accessor("blackList.comment", {
    id: "comment",
    header: () => t("Komentarz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <EditableComment
          isDisabled={!!info.row.original.bannedDate}
          value={info.getValue() || ""}
          organizationId={info.row.original.id}
        />
      </Td>
    ),
  }),
  columnHelper.accessor("id", {
    id: "id",
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          {!info.row.original.bannedDate && (
            <RemoveFromBlackListBtn
              organizationId={info.getValue()}
              organizationName={info.row.original.details.displayName}
            />
          )}
          <LinkCell path={`/${info.row.original.id}`} label={t("Przejdź")} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  organizations: IOrganization[];
  isLoading: boolean;
}

const BlackListedOrganizationsTableConnected = ({
  organizations,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (organizations?.length === 0) {
    return <TableEmptyState title={t("Czarna lista jest pusta")} />;
  }

  const bannedOrganizations = organizations.filter(
    (org) => org.bannedDate || org.blackList
  );

  const sortedRecords = sortBy(bannedOrganizations, function (data) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return new Date(data.bannedDate || data.blackList?.deactivationDate!);
  });

  return (
    <Table<IOrganization>
      data={sortedRecords}
      columns={columns}
      globalSearchParam="blackListSearch"
    />
  );
};

export const BlackListedOrganizationsTable = () => {
  const { data, isLoading } = useBlackListQuery({ active: false });
  const { data: organizations, isPending } = useOrganizationsQuery();

  return (
    <Card variant="sawpe">
      <Tabs variant="enclosed">
        <CardHeader
          justifyContent="space-between"
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          <TabList w="full">
            <Tab>
              <Heading fontSize="lg">{t("Czarna lista podmiotów")}</Heading>
            </Tab>
            <Tab>
              <Heading fontSize="lg">{t("Historia czarnej listy")}</Heading>
            </Tab>
          </TabList>
          <Spacer display={{ base: "block", md: "none" }} />
          <SearchInput globalSearchParam="blackListSearch" />
        </CardHeader>

        <TabPanels>
          <TabPanel>
            <CardBody>
              <BlackListedOrganizationsTableConnected
                organizations={organizations?.organizations}
                isLoading={isPending}
              />
            </CardBody>
          </TabPanel>
          <TabPanel>
            <CardBody>
              <BlackListTable
                blackList={data?.blackList}
                isLoading={isLoading}
              />
            </CardBody>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};
