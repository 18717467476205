import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  Heading,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { HTTPError } from "ky";
import { settings } from "settings/getSettings";
import {
  ConfigProvider,
  Dropzone,
  EPW,
  IVerificationResult,
  SearchBar,
  VerificationResult,
} from "verification-widget";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { useDownloadEPW } from "modules/verification/infrastructure/useDownloadEPW";
import { useListenForVerificationStatus } from "modules/verification/infrastructure/useListenForVerificationStatus";
import { useSearch } from "modules/verification/infrastructure/useSearch";

import { ListenForVerificationToaster } from "../ListenForVerificationToaster";
import { ResultInterpretationRequest } from "../ResultInterpretationRequest";

interface IProps {
  isLoading: boolean;
  inactive: boolean;
  contextId: string;
  filesLimit: number | null;
  allowDispositionDownload: boolean;
}

const StartVerificationConnected = ({
  isLoading,
  inactive,
  contextId,
  filesLimit,
  allowDispositionDownload,
}: IProps) => {
  const [searchParams] = useSearchParams();
  const [verification, setVerification] = useState<IVerificationResult | null>(
    null
  );

  const [listenForVerification, verificationStatus, abort] =
    useListenForVerificationStatus(contextId);
  const { mutateAsync: downloadEPW } = useDownloadEPW();
  const { mutateAsync: search } = useSearch();

  useEffect(() => {
    const verificationId = searchParams.get("searchVerificationId");
    if (verificationId) {
      search(verificationId)
        .then((result) => {
          setVerification(result);
        })
        .catch((error) => {
          if (error instanceof HTTPError && error.response.status === 404) {
            toastWithError({
              error,
              description: t("Nie udało się odnaleźć żadnego wyniku."),
              duration: 12_000,
            });
            return;
          }

          toastWithError({ error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <VStack align="stretch" maxW="1200px" mx="auto" pb={16}>
        <Skeleton height="300px" />
        <Skeleton height="50px" />
      </VStack>
    );
  }

  if (inactive) {
    return (
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {t("Brak aktywnego pakietu")}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {t("Skontaktuj się z administratorem systemu.")}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <VStack align="stretch" maxW="1200px" mx="auto" pb={16}>
      <Dropzone
        fileLimit={filesLimit!}
        loadingState={verificationStatus}
        onVerify={async (files, reset) => {
          listenForVerification({
            files: files,
            callback: (result) => {
              setVerification(result);
              reset();
            },
            onError: (error) => {
              toastWithError({ error });
            },
          });
        }}
        abort={abort}
      />
      <SearchBar
        onSearch={async (value) => {
          try {
            const result = await search(value);
            setVerification(result);
          } catch (error) {
            if (error instanceof HTTPError && error.response.status === 404) {
              toastWithError({
                error,
                description: t("Nie udało się odnaleźć żadnego wyniku."),
              });
              return;
            }

            toastWithError({ error });
          }
        }}
      />
      {verification && (
        <>
          <EPW
            verificationId={verification.idRequest}
            allowDownload={allowDispositionDownload}
            downloadEPW={async (type, version) => {
              try {
                await downloadEPW({
                  verificationId: verification.idRequest,
                  type,
                  version,
                });
              } catch (error) {
                toastWithError({ error });
              }
            }}
            downloadV3
          />
          <VerificationResult result={verification} />
          <ResultInterpretationRequest
            verificationId={verification.idRequest}
          />
        </>
      )}
    </VStack>
  );
};

export const StartVerification = () => {
  const contextId = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.id;
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  return (
    <>
      <ListenForVerificationToaster />
      <Card>
        <CardBody>
          <VStack align="stretch">
            <Heading fontSize="lg" px="2" py="3">
              {t("Weryfikuj pliki")}
            </Heading>
          </VStack>
          <ConfigProvider
            value={{
              hostApi: settings.API_HOST!,
              canDownloadCertificate: true,
            }}
          >
            <StartVerificationConnected
              isLoading={isLoading}
              inactive={!data?.organization.tariffs.current?.isActive}
              contextId={contextId!}
              filesLimit={
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                data?.organization.tariffs.current?.filesVerificationLimit
                  .limit!
              }
              allowDispositionDownload={
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                data?.organization.tariffs.current?.allowDispositionDownload!
              }
            />
          </ConfigProvider>
        </CardBody>
      </Card>
    </>
  );
};
