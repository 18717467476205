import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { CheckCircleOutlineIcon } from "components/icons/CheckCircleOutlineIcon";

import { useMarkAllVerificationAsRead } from "modules/verification/infrastructure/useMarkAllVerificationAsRead";

interface IProps {
  contextId: string;
  hasPendingVerifications: boolean;
}

export const MarkAllAsReadBtn = ({
  contextId,
  hasPendingVerifications,
}: IProps) => {
  const { mutateAsync: markAllAsRead, isPending } =
    useMarkAllVerificationAsRead(contextId!);

  return (
    <Button
      leftIcon={<CheckCircleOutlineIcon />}
      variant="outline"
      colorScheme="purple"
      size="sm"
      data-testid="test-all-workers"
      onClick={() => markAllAsRead()}
      title={t("Oznacz wszystkie jako przeczytane")}
      isLoading={isPending}
      isDisabled={!hasPendingVerifications}
    >
      {t("Oznacz wszystkie jako przeczytane")}
    </Button>
  );
};
