import { useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";

export const useUnreadVerificationToast = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    title: t("Sukces!"),
    duration: 8_000,
    isClosable: true,
    render: ({ onClose }) => (
      <Alert status="info" variant="subtle" rounded="md">
        <AlertIcon />
        <Box>
          <AlertTitle>{t("Wyniki weryfikacji")}</AlertTitle>
          <AlertDescription>
            {t(
              "Mamy wyniki Twojej weryfikacji. Aby je sprawdzić przejdź do historii weryfikacji lub kliknij poniższy link."
            )}
            <Button
              variant="link"
              fontWeight="400"
              textDecoration="underline"
              color="gray.800"
              onClick={() => {
                navigate(`/${organizationId}/historia-weryfikacji?unread=true`);
                onClose();
              }}
            >
              {t("Przejdź do historii weryfikacji")}
            </Button>
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </Alert>
    ),
  });

  return toast;
};
