import { settings } from "settings/getSettings";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const useCreateConnectionUrl = () => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext;

  const url = new URL(
    `${settings.MERCURE ?? import.meta.env.VITE_APP_MERCURE}/.well-known/mercure`
  );

  switch (context?.type) {
    case "system_admin":
      url.searchParams.append("topic", `system-admin`);
      break;
    case "system_manager":
      url.searchParams.append("topic", `system-manager`);
      break;
    case "context_manager":
    case "context_user":
      url.searchParams.append("topic", `contextId-${context.id}`);
      break;
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-non-null-asserted-optional-chain
      const contextType: never = context?.type!;
      break;
    }
  }

  return url;
};
