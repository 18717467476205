import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { useEventSourceConsumer } from "modules/events/application/EventSourceProvider";

import { IWorker } from "../application/types/IWorker";

export const useListenForIntegrationConfirmation = () => {
  const queryClient = useQueryClient();

  const { subscribe, unsubscribe } = useEventSourceConsumer();

  useEffect(() => {
    subscribe({
      id: "worker-integration-list",
      eventType: "worker_integration_list_update",
      onEvent: (event) => {
        queryClient.setQueryData<{ organizations: IWorker[] }>(
          ["workers"],
          (oldData) => {
            if (!oldData) {
              return { organizations: [] };
            }

            const newData = oldData?.organizations.map((worker) => {
              if (worker.id === event.id) {
                return event;
              }

              return worker;
            });

            return { organizations: newData };
          }
        );
      },
    });

    // Cleanup on component unmount
    return () => {
      unsubscribe("worker-integration-list");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient]);
};
