import { Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

export const RelativeTime = ({ value }: { value: string }) => {
  const diff = dayjs(value).diff(dayjs(), "day");

  if (diff < 0) {
    return (
      <Text textColor="gray.600">
        {t("zablokowano {{value, relativetime}}", {
          value: diff,
        })}
      </Text>
    );
  }

  if (diff === 0) {
    const diff = dayjs(value).diff(dayjs(), "hours");

    if (diff < 0) {
      return (
        <Text textColor="gray.600">
          {t("zablokowano {{value}} godzin temu", {
            value: Math.abs(diff),
          })}
        </Text>
      );
    }

    return (
      <Text textColor="gray.600">
        {t("pozostało {{value}} godzin", {
          value: diff,
        })}
      </Text>
    );
  }

  if (diff === 1) {
    const diff = dayjs(value).diff(dayjs(), "hours");

    return (
      <Text textColor="gray.600">
        {t("pozostał 1 dzień", {
          value: diff,
        })}
      </Text>
    );
  }

  return (
    <Text textColor="gray.600">
      {t("pozostało {{value}} dni", {
        value: diff,
      })}
    </Text>
  );
};
