import { Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { AlertDialog } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";

import { useRemoveFromBlackList } from "modules/organization/infrastructure/useRemoveFromBlackList";

interface IProps {
  id: string;
  organizationId: string;
  organizationName: string;
}

export const RemoveFromBlackListConfirmation = ({
  id,
  organizationId,
  organizationName,
}: IProps) => {
  const { mutateAsync: removeFromBlackList } =
    useRemoveFromBlackList(organizationId);

  return (
    <AlertDialog
      id={id}
      title={t("Usuń z czarnej listy")}
      body={
        <VStack align="stretch" spacing={4} fontSize="md">
          <Text>
            {t(
              "Czy na pewno chcesz usunąć organizację {{organizationName}} z czarnej listy?",
              {
                organizationName,
              }
            )}
          </Text>
        </VStack>
      }
      colorScheme="purple"
      size="xl"
      confirmLabel={t("Zatwierdź")}
      onConfirm={async () => {
        try {
          await removeFromBlackList();
        } catch (error) {
          toastWithError({
            error,
          });
        }
      }}
    />
  );
};
