import { Link, useLocation } from "react-router-dom";

import { IconButton } from "@chakra-ui/react";
import { t } from "i18next";

import { LogsIcon } from "components/icons/LogsIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

export const SystemEventsButton = () => {
  const { pathname } = useLocation();

  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  const availableFor = ["system_admin", "system_manager"];

  if (!availableFor.includes(context!)) {
    return null;
  }

  return (
    <IconButton
      w="100%"
      as={Link}
      to={"/zdarzenia-systemowe"}
      title={t("Zdarzenia systemowe")}
      variant={{ base: "link", lg: "ghost" }}
      aria-label="system-events"
      icon={<LogsIcon height="20px" />}
      background="transparent"
      isActive={pathname === `/zdarzenia-systemowe`}
    >
      {t("Zdarzenia systemowe")}
    </IconButton>
  );
};
