import dayjs from "dayjs";

import { client } from "utils/http";

import { TariffTypes } from "modules/tariffs/application/types/ITariff";

interface UsersCountReport {
  organizationId: string;
  organizationName: string;
  usersCount: number;
  tariffType: TariffTypes | null;
}

export const getUsersCountReportCSV = async () => {
  const { statistics } = await client.get<{ statistics: UsersCountReport[] }>(
    `statistics/usersCountReport`
  );

  // Sortowanie według TariffType
  const sortedStatistics = statistics.sort((a, b) => {
    if (a.tariffType === null && b.tariffType === null) return 0;
    if (a.tariffType === null) return 1;
    if (b.tariffType === null) return -1;
    return a.tariffType.localeCompare(b.tariffType);
  });

  // Konwersja danych do CSV
  const csvData = convertToCSV(sortedStatistics);

  // Pobranie pliku CSV
  downloadCSV(csvData, `${dayjs().format("YYYY-MM-DD HH:mm")}.csv`);
};

// Funkcja konwertująca dane do CSV
const convertToCSV = (data: UsersCountReport[]): string => {
  const headers = ["Nazwa organizacji", "Liczba użytkowników", "Pakiet"];

  const rows = data.map((item) =>
    [
      item.organizationName.replaceAll(",", ""),
      item.usersCount,
      item.tariffType ?? "Brak",
    ].join(",")
  );

  return [headers.join(","), ...rows].join("\n");
};

// Funkcja pobierająca plik CSV
const downloadCSV = (data: string, filename: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
};
