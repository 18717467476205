import { useParams } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { CancelIcon } from "components/icons/CancelIcon";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";

import { AddToBlackListModal } from "../BlackListedOrganizationsTable/AddToBlackListModal";
import { RemoveFromBlackListConfirmation } from "../BlackListedOrganizationsTable/RemoveFromBlackListConfirmation";

interface IProps {
  isOnBlackList: boolean;
}

export const BlackListBtn = ({ isOnBlackList }: IProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);
  const id = `add-to-black-list-${organizationId}`;
  const onOpenAddToBlackList = useOpenDialog(id);

  const removeId = `remove-from-black-list-${organizationId}`;
  const onOpenRemoveFromBlackList = useOpenDialog(removeId);

  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.type;

  const withAccess = ["system_admin", "system_manager"];

  if (!withAccess.includes(context!) || isLoading) {
    return null;
  }

  if (data?.organization.bannedDate) {
    return null;
  }

  return (
    <>
      <AddToBlackListModal
        id={id}
        organizationId={organizationId!}
        organizationName={data?.organization.details.displayName}
      />
      <RemoveFromBlackListConfirmation
        id={removeId}
        organizationId={organizationId!}
        organizationName={data?.organization.details.displayName}
      />
      <Button
        leftIcon={<CancelIcon />}
        variant="outline"
        colorScheme="purple"
        size="sm"
        data-testid="add-to-black-list"
        onClick={
          isOnBlackList ? onOpenRemoveFromBlackList : onOpenAddToBlackList
        }
        flexShrink={0}
        title={
          isOnBlackList
            ? t("Usuń z czarnej listy")
            : t("Dodaj do czarnej listy")
        }
      >
        {isOnBlackList
          ? t("Usuń z czarnej listy")
          : t("Dodaj do czarnej listy")}
      </Button>
    </>
  );
};
