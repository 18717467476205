import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { SearchInput } from "components/Filters/SearchInput";

import { useListenForIntegrationConfirmation } from "../infrastructure/useListenForIntegrationConfirmation";
import { useWorkersQuery } from "../infrastructure/useWorkersQuery";
import { TestAllWorkersButton } from "./common/TestAllWorkersButton";
import { WorkersTable } from "./common/WorkersTable";

export const ActiveWorkersTable = () => {
  const { data, isLoading } = useWorkersQuery();
  useListenForIntegrationConfirmation();

  return (
    <Card variant="sawpe" id="active-workers">
      <CardHeader>
        <Stack w="full" direction="row" align="center" spacing={6} wrap="wrap">
          <Heading fontSize="lg">{t("Aktywne integracje enterprise")}</Heading>
          <TestAllWorkersButton />
          <Spacer />
          <SearchInput
            globalSearchParam="activeWorkersSearch"
            data-testid="active-workers-search"
          />
        </Stack>
      </CardHeader>
      <CardBody>
        <WorkersTable
          workers={data?.organizations.filter((org) => org.active)}
          isLoading={isLoading}
          globalSearchParam="activeWorkersSearch"
        />
      </CardBody>
    </Card>
  );
};
