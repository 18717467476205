import { ButtonGroup, Tag, Td, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { LinkCell } from "components/Table/LinkCell";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IBlackListProcess } from "modules/organization/application/types/IBlackListProcess";

import { EditableComment } from "./EditableComment";
import { RelativeTime } from "./RelativeTime";

const columnHelper = createColumnHelper<IBlackListProcess>();

const columns = [
  columnHelper.accessor("organizationName", {
    id: "name",
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td data-testid="organization-name" whiteSpace="nowrap" maxW="250px">
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor("deactivationDate", {
    id: "deactivationDate",
    header: () => t("Planowana data dezaktywacji"),
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDate date={info.getValue()} />
        {!info.row.original.canceled && (
          <RelativeTime value={info.getValue()} />
        )}
      </Td>
    ),
  }),
  columnHelper.accessor(
    (row) => {
      switch (true) {
        case row.canceled:
          return "canceled";
        case row.completed:
          return "completed";
        default:
          return "pending";
      }
    },
    {
      id: "status",
      header: () => t("Status"),
      cell: (info) => {
        const Tags = {
          canceled: (
            <Tag
              size="sm"
              variant="subtle"
              colorScheme="green"
              verticalAlign="middle"
            >
              {t("Anulowano")}
            </Tag>
          ),
          completed: (
            <Tag
              size="sm"
              variant="subtle"
              colorScheme="red"
              verticalAlign="middle"
            >
              {t("Zablokowano")}
            </Tag>
          ),
          pending: (
            <Tag
              size="sm"
              variant="subtle"
              colorScheme="orange"
              verticalAlign="middle"
            >
              {t("Oczekujący")}
            </Tag>
          ),
        };
        return (
          <Td whiteSpace="normal" data-testid="black-list-status">
            {Tags[info.getValue()]}
          </Td>
        );
      },
    }
  ),
  columnHelper.accessor("comment", {
    id: "comment",
    header: () => t("Komentarz"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <EditableComment
          isDisabled={info.row.original.completed || info.row.original.canceled}
          value={info.getValue() || ""}
          organizationId={info.row.original.id}
        />
      </Td>
    ),
  }),
  columnHelper.accessor("organizationId", {
    id: "id",
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <LinkCell path={`/${info.getValue()}`} label={t("Przejdź")} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

interface IProps {
  blackList: IBlackListProcess[];
  isLoading: boolean;
}

export const BlackListTable = ({ blackList, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (blackList?.length === 0) {
    return <TableEmptyState title={t("Czarna lista jest pusta")} />;
  }

  const sortedRecords = reverse(
    sortBy(blackList, function (data) {
      return new Date(data.createdAt!);
    })
  );

  return (
    <Table<IBlackListProcess>
      data={sortedRecords}
      columns={columns}
      globalSearchParam="blackListSearch"
    />
  );
};
