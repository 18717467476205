import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

interface MutationVariables {
  days: number;
  comment?: string;
}

export const useAddToBlackList = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["add-to-black-list"],
    mutationFn: (variables: MutationVariables) => {
      return client.post(`organizations/${organizationId}/blackList`, {
        body: {
          deactivationInDays: Number(variables.days),
          comment: variables.comment,
        },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "details"],
      });
    },
  });
};
