import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { SearchInput } from "components/Filters/SearchInput";
import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { IEvent } from "modules/eventLog/application/types/IEvent";
import { useEventsQuery } from "modules/eventLog/infrastructure/useEventsQuery";

import { SystemEventPayloadBtn } from "./SystemEventPayloadBtn";
import { toReadableEventName } from "./toReadableEventName";

const columnHelper = createColumnHelper<IEvent>();

const columns = [
  columnHelper.accessor("accountName", {
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td whiteSpace="normal">{info.getValue() || t("System")}</Td>
    ),
  }),
  columnHelper.accessor("ip", {
    header: () => t("IP"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue() || "---"}</Td>,
  }),
  columnHelper.accessor("createdAt", {
    header: () => t("Data"),
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal">
        <FormatDateTime date={info.getValue()} />
      </Td>
    ),
  }),
  columnHelper.accessor("payload.organizationName", {
    id: "organizationName",
    header: () => t("Podmiot"),
    cell: (info) => (
      <Td whiteSpace="normal">
        {(info.getValue() as unknown as string) || "---"}
      </Td>
    ),
  }),
  columnHelper.accessor((row) => toReadableEventName(row), {
    id: "eventName",
    filterFn: (row, _id, filterValue: string[]) => {
      if (!filterValue.length) return true;

      return filterValue.some((value) => row.original.name.includes(value));
    },
    header: () => t("Rodzaj aktywności"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("id", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <SystemEventPayloadBtn
          sysEventId={info.getValue()}
          payload={
            "payload" in info.row.original
              ? info.row.original.payload
              : undefined
          }
        />
      </Td>
    ),
  }),
];

interface IProps {
  events: IEvent[];
  isLoading: boolean;
  organizationNameVisible?: boolean;
}

export const EventTableConnected = ({
  events,
  isLoading,
  organizationNameVisible = false,
}: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (events.length === 0) {
    return <TableEmptyState title={t("Lista aktywności jest pusta.")} />;
  }

  const sortedRecords = reverse(
    sortBy(events, function (data) {
      return new Date(data.createdAt);
    })
  );

  return (
    <Table<IEvent>
      data={sortedRecords}
      columns={columns}
      columnVisibility={{
        organizationName: organizationNameVisible,
      }}
    />
  );
};

export const EventHistoryTable = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useEventsQuery(organizationId!);

  return (
    <Card variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Historia aktywności")}</Heading>
        <Spacer />
        <SearchInput placeholder={t("Nazwa, rodzaj aktywności lub IP")} />
      </CardHeader>
      <CardBody>
        <EventTableConnected events={data?.events} isLoading={isLoading} />
      </CardBody>
    </Card>
  );
};
