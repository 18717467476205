import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { HStack, VStack, Link as ChakraLink, Text } from "@chakra-ui/react";
import { t } from "i18next";

import { ArrowLeftIcon } from "components/icons/ArrowLeftIcon";

import { ApiKeysTable } from "modules/apiKey/presentation/ApiKeysTable";
import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { BlackListAlert } from "modules/organization/presentation/BlackListAlert";
import { ManagersTable } from "modules/organization/presentation/ManagersTable";
import { OrganizationDescription } from "modules/organization/presentation/OrganizationDescription";
import { OrganizationHeader } from "modules/organization/presentation/OrganizationHeader";
import { UsersTable } from "modules/organization/presentation/UsersTable";
import { AddTariffModal } from "modules/tariffs/presentation/AddTariffButton/AddTariffModal";
import { CurrentTariff } from "modules/tariffs/presentation/CurrentTariff";
import { NextTariff } from "modules/tariffs/presentation/NextTariff";
import { TariffDraft } from "modules/tariffs/presentation/TariffDraft";
import { TariffUsage } from "modules/tariffs/presentation/TariffUsage";
import { InterpretationRequestHistoryTable } from "modules/verification/presentation/InterpretationRequestHistoryTable";

import { NoUsersAlert } from "./NoUsersAlert";
import { OrganizationNavigation } from "./OrganizationNavigation";

export const OrganizationConfig = withTranslation()(() => {
  const availableTypes = ["system_admin", "system_manager"];

  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  const contextType = userInfo?.currentlySelectedContext?.type;

  return (
    <>
      <AddTariffModal />
      <VStack align="stretch" gap="8">
        <BlackListAlert />
        <GoBackLink />
        <OrganizationHeader />
        <NoUsersAlert />
        <HStack gap="8" align="start">
          <OrganizationNavigation />
          <VStack align="stretch" w="100%" gap="6" p="2px" overflow="auto">
            {contextType === "context_manager" && <TariffUsage />}
            {availableTypes.includes(contextType!) && (
              <>
                <TariffDraft />
                <CurrentTariff />
                <NextTariff />
              </>
            )}
            <OrganizationDescription />
            <ApiKeysTable />
            <ManagersTable />
            <UsersTable />
            <InterpretationRequestHistoryTable />
          </VStack>
        </HStack>
      </VStack>
    </>
  );
});

const GoBackLink = () => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );

  if (userInfo?.currentlySelectedContext?.type !== "system_manager") {
    return null;
  }

  return (
    <ChakraLink
      as={Link}
      to="/organizacje"
      color="purple.500"
      w="max-content"
      mb={-4}
    >
      <HStack w="max-content" spacing="1">
        <ArrowLeftIcon size="16px" />
        <Text fontSize="sm">{t("Powrót")}</Text>
      </HStack>
    </ChakraLink>
  );
};
