import { useForm, useWatch } from "react-hook-form";

import {
  Button,
  Input,
  InputGroup,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import dayjs from "dayjs";
import { t } from "i18next";

import { Modal } from "components/Dialog";
import { toastWithError } from "components/ErrorHandling/toastWithError";
import { FormControl } from "components/Form/FormControl";

import { useAddToBlackList } from "modules/organization/infrastructure/useAddToBlackList";

interface IProps {
  id: string;
  organizationId: string;
  organizationName: string;
}

export const AddToBlackListModal = ({
  id,
  organizationId,
  organizationName,
}: IProps) => {
  const isMutating = useIsMutating({
    mutationKey: ["add-to-black-list"],
  });

  return (
    <Modal id={id}>
      {({ onClose }) => {
        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t("Potwierdzenie dodania na czarną listę")}
              </ModalHeader>
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <Content
                  organizationId={organizationId}
                  organizationName={organizationName}
                  onClose={onClose}
                />
              </ModalBody>
              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose} isLoading={!!isMutating}>
                    {t("Anuluj")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    data-testid="add-to-black-list-button"
                    form="black-list-form"
                    isLoading={!!isMutating}
                  >
                    {t("Dodaj")}
                  </Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

interface IContentProps {
  organizationId: string;
  organizationName: string;
  onClose: () => void;
}

const Content = ({
  organizationId,
  organizationName,
  onClose,
}: IContentProps) => {
  const { mutateAsync: addToBlackList } = useAddToBlackList(organizationId);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<{
    days: number;
    comment: string;
  }>({
    defaultValues: {
      days: 7,
      comment: "",
    },
  });

  const banDate = useWatch({
    name: "days",
    control,
  });

  return (
    <form
      id="black-list-form"
      onSubmit={handleSubmit(async (data) => {
        try {
          await addToBlackList({ days: data.days, comment: data.comment });
          onClose();
        } catch (error) {
          toastWithError({
            error,
          });
        }
      })}
    >
      <VStack align="stretch" gap="6">
        <Text>
          {t(
            "Organizacja {{organizationName}} zostanie dodana na czarną listę.",
            {
              organizationName,
            }
          )}
        </Text>
        <FormControl
          formLabel={t("Ilość dni")}
          isRequired
          formHelperText={t(
            "Po upływie podanej ilości dni użytkownicy stracą możliwość weryfikacji podpisów."
          )}
          formErrorMessage={errors.days?.message}
        >
          <Input
            type="number"
            step="1"
            {...register("days", {
              required: true,
              min: {
                value: 1,
                message: t("Minimum 1 dzień"),
              },
              max: {
                value: 14,
                message: t("Maximum 14 dni"),
              },
            })}
          />
        </FormControl>
        <FormControl
          formLabel={t("Komentarz")}
          formErrorMessage={errors.comment?.message}
          formHelperText={t(
            "Treść komentarza nie jest widoczna dla użytkowników."
          )}
        >
          <Textarea
            {...register("comment", {
              maxLength: {
                value: 255,
                message: t("Maksymalnie 255 znaków"),
              },
            })}
          />
        </FormControl>
        <Text>
          {t("Planowana data blokady konta: {{banDate}}", {
            banDate: dayjs()
              .add(banDate, "day")
              .add(1, "day")
              .format("DD.MM.YYYY"),
          })}
        </Text>
      </VStack>
    </form>
  );
};
