import { useEffect } from "react";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { usePendingVerificationsQuery } from "../infrastructure/usePendingVerificationsQuery";
import { useUnreadVerificationToast } from "./useUnreadVerificationToast";

interface IProps {
  contextId: string;
}

const UnreadVerificationsToasterConnected = ({ contextId }: IProps) => {
  const { data, isLoading } = usePendingVerificationsQuery(contextId);

  const toast = useUnreadVerificationToast();

  useEffect(() => {
    if (!isLoading && data?.pendingVerifications.length > 0) {
      toast();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return null;
};

export const UnreadVerificationsToaster = () => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext;

  if (!context || context.type !== "context_user") {
    return null;
  }

  return <UnreadVerificationsToasterConnected contextId={context.id} />;
};
