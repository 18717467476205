import { Link, VStack } from "@chakra-ui/react";
import { t } from "i18next";

export const DashboardNavigation = () => {
  return (
    <VStack
      align="stretch"
      pl={3}
      minW="160px"
      spacing="2"
      color="gray.600"
      position="sticky"
      top="0px"
      display={{ base: "none", lg: "flex" }}
    >
      <Link href="#managers">{t("Zarządcy")}</Link>
      <Link href="#admins">{t("Administratorzy")}</Link>
      <Link href="#active-workers">{t("Aktywne integracje")}</Link>
      <Link href="#inactive-workers">{t("Nieaktywne integracje")}</Link>
    </VStack>
  );
};
