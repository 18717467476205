import { VerificationEvent } from "./types/IEvent";
import { ISubscriber } from "./types/ISubscriber";

export const handleVerificationResultStrategy = (
  subscribers: ISubscriber[],
  data: VerificationEvent
) => {
  const subsForResult = subscribers.filter(
    (subscriber) =>
      subscriber.eventType === "verification_process_update" &&
      subscriber.id === data.verificationId
  );

  if (subsForResult.length > 0) {
    subsForResult.forEach((subscriber) => {
      subscriber.onEvent(data);
    });
    return;
  }

  const subsForUnread = subscribers.filter(
    (subscriber) =>
      subscriber.eventType === "verification_process_update" &&
      subscriber.id === "new-unread-verification"
  );

  subsForUnread.forEach((subscriber) => {
    subscriber.onEvent(data);
  });
};
