import { useCallback, useMemo } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useLocalStorage = <T = string>(key: string) => {
  const queryKey = useMemo(() => ["localStorage", key], [key]);
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn: () => {
      const storedValue = localStorage.getItem(key);

      if (!storedValue) return null;

      try {
        if (/^[\\[{"]|^-?\d/.test(storedValue)) {
          const parsed = JSON.parse(storedValue);

          return parsed as T;
        }
        // Jeśli nie wygląda na JSON, zwracamy surowy string
        return storedValue as unknown as T;
      } catch {
        return storedValue as unknown as T;
      }
    },
  });

  const setValue = useCallback(
    (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      queryClient.setQueryData(queryKey, value);
    },
    [key, queryClient, queryKey]
  );

  return [query.data, setValue] as const;
};
