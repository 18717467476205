import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { formatNumber } from "utils/i18n/formatNumber";
import { relativeTime } from "utils/i18n/relativeTime";

import { CircularProgress } from "components/CircularProgress";
import { FormatDate } from "components/FormatDate";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { RocketIcon } from "components/icons/RocketIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { ITariff } from "modules/tariffs/application/types/ITariff";
import { useTariffsExpirationsQuery } from "modules/tariffs/infrastructure/useTariffsExpirationsQuery";

import { ExpirationAlert } from "../common/ExpirationAlert";

interface IProps {
  isLoading: boolean;
  tariff: ITariff | null;
}

const TariffUsageConnected = ({ isLoading, tariff }: IProps) => {
  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="170px" />
      </Stack>
    );
  }

  if (tariff === null) {
    return <TableEmptyState title={t("Brak aktywnego pakietu")} />;
  }

  const totalCount =
    tariff.usageLimit.apiVerificationCount +
    tariff.usageLimit.defaultVerificationCount;

  const percentageUsage = (totalCount / tariff.usageLimit.totalLimit!) * 100;

  const isUnlimited = tariff.usageLimit.type === "UNLIMITED";

  return (
    <HStack spacing={4}>
      {isUnlimited ? (
        <RocketIcon h="100px" w="100px" p={3} color="purple.500" />
      ) : (
        <CircularProgress percentage={percentageUsage} size="100px" />
      )}
      <VStack spacing="2" align="stretch" fontWeight="medium">
        <Text fontSize="lg" fontWeight="600">
          {t("Pakiet {{size}}", { size: tariff.type })}
        </Text>
        {isUnlimited ? (
          <Text textColor="gray.600">
            {t("Weryfikacje wykonane w ramach pakietu: {{totalCount}}", {
              totalCount: formatNumber(totalCount),
            })}
          </Text>
        ) : (
          <Text textColor="gray.600">
            {formatNumber(totalCount)}/
            {formatNumber(tariff.usageLimit.totalLimit!)}
          </Text>
        )}
        <Text textColor="gray.600">
          {t("Ważny do")} <FormatDate date={tariff.validTo} />{" "}
          {relativeTime(tariff.validTo)}
        </Text>
      </VStack>
    </HStack>
  );
};

export const TariffUsage = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);
  const { data: expirations } = useTariffsExpirationsQuery(organizationId!);

  return (
    <Card id="tariffs" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Wykorzystanie pakietu")}</Heading>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={4}>
          <ExpirationAlert
            expirations={expirations?.tariffsReminders.filter(
              ({ tariffId }) =>
                tariffId === data?.organization?.tariffs.current?.id
            )}
            nextTariff={data?.organization?.tariffs.next}
          />
          <TariffUsageConnected
            isLoading={isLoading}
            tariff={data?.organization?.tariffs.current}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};
