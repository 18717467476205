import { MenuItem } from "@chakra-ui/react";
import { t } from "i18next";

import { toastWithError } from "components/ErrorHandling/toastWithError";

import { getUsersCountReportCSV } from "modules/organization/infrastructure/getUsersCountReportCSV";

export const DownloadUsersCountReportMenuItem = () => {
  return (
    <MenuItem
      onClick={async () => {
        try {
          await getUsersCountReportCSV();
        } catch (e) {
          toastWithError({
            error: e,
            description: t("Nie udało się pobrać zestawienia CSV"),
          });
        }
      }}
    >
      {t("Pobierz zestawienie CSV")}
    </MenuItem>
  );
};
