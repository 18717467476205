import {
  Button,
  ButtonGroup,
  InputGroup,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";

import { Modal, useOpenDialog } from "components/Dialog";
import { SearchInput } from "components/Filters/SearchInput";
import { FormatDate } from "components/FormatDate";
import { Table } from "components/Table";
import { LinkCell } from "components/Table/LinkCell";

import { UsageLimit } from "modules/organization/presentation/OrganizationsTable/UsageLimit";

import { IAllTariffsExpirations } from "../application/types/IAllTariffsExpirations";

type Payload = { expirations: IAllTariffsExpirations[]; type: string };

const id = "expiring-tariffs-modal";

export const useOpenExpiringTariffsModal = () => {
  return useOpenDialog<Payload>(id);
};

export const ExpiringTariffsModal = () => {
  return (
    <Modal<Payload> id={id} size={"4xl"}>
      {({ payload, onClose }) => {
        if (payload === undefined) return null;

        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {getMeta(payload.type)}
                <SearchInput globalSearchParam="expiredTariffsSearch" />
              </ModalHeader>
              <ModalCloseButton />

              <ModalBody as={VStack} align="stretch" spacing={6}>
                <Table<IAllTariffsExpirations>
                  data={payload.expirations}
                  columns={columns}
                  globalSearchParam="expiredTariffsSearch"
                />
              </ModalBody>

              <ModalFooter>
                <InputGroup gap="2" justifyContent="end">
                  <Button onClick={onClose}>{t("Anuluj")}</Button>
                </InputGroup>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

const columnHelper = createColumnHelper<IAllTariffsExpirations>();

const columns = [
  columnHelper.accessor("organizationName", {
    id: "organizationName",
    header: () => t("Nazwa"),
    cell: (info) => (
      <Td whiteSpace="nowrap">
        <Text isTruncated>{info.getValue()}</Text>
      </Td>
    ),
  }),
  columnHelper.accessor("tariffType", {
    id: "tariffType",
    header: () => t("Pakiet"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("validTo", {
    id: "validTo",
    header: () => t("Umowa do"),
    cell: (info) => {
      return (
        <Td>
          <FormatDate date={info.getValue()} />
        </Td>
      );
    },
  }),
  columnHelper.accessor("totalLimit", {
    id: "totalLimit",
    header: () => t("Wykorzystanie"),
    cell: (info) => {
      return (
        <Td whiteSpace="normal">
          <UsageLimit
            type={
              info.row.original.tariffType === "E" ? "UNLIMITED" : "LIMITED"
            }
            defaultVerificationCount={info.row.original.usage}
            apiVerificationCount={0}
            totalLimit={info.getValue()}
          />
        </Td>
      );
    },
  }),
  columnHelper.accessor("organizationId", {
    header: () => "",
    enableSorting: false,
    cell: (info) => (
      <Td whiteSpace="normal" isNumeric>
        <ButtonGroup>
          <LinkCell path={`/${info.getValue()}`} label={t("Przejdź")} />
        </ButtonGroup>
      </Td>
    ),
  }),
];

const getMeta = (type: string) => {
  switch (type) {
    case "usage_limit":
      return t("Podmioty z kończącym się limitem");
    case "one_day":
      return t("Pakiet kończy się dziś");
    case "one_week":
      return t("Pakiety kończące się w najbliższych 7 dniach");
    case "one_month":
      return t("Pakiety kończące się w najbliższym miesiącu");
    default:
      return t("Podmioty");
  }
};
