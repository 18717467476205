import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";

import { useListenForPendingVerifications } from "../infrastructure/useListenForPendingVerifications";
import { useUnreadVerificationToast } from "./useUnreadVerificationToast";

const ListenForVerificationToasterConnected = () => {
  const toast = useUnreadVerificationToast();

  useListenForPendingVerifications(toast);

  return null;
};

export const ListenForVerificationToaster = () => {
  const context = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext;

  if (!context || context.type !== "context_user") {
    return null;
  }

  return <ListenForVerificationToasterConnected />;
};
