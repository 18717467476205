import { useSearchParams } from "react-router-dom";

import { InputGroup, InputLeftElement, InputProps } from "@chakra-ui/react";
import { t } from "i18next";

import { DebouncedInput } from "components/DebouncedInput";
import { SearchIcon } from "components/icons/SearchIcon";

interface IProps
  extends Omit<InputProps, "onChange" | "value" | "placeholder"> {
  globalSearchParam?: string;
  placeholder?: string;
}

export const SearchInput = ({
  globalSearchParam = "globalSearch",
  placeholder = t("Szukaj"),
  ...props
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <InputGroup maxW="322px">
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <DebouncedInput
        value={searchParams.get(globalSearchParam) || ""}
        onChange={(e) => {
          if (typeof e === "string") {
            searchParams.set(globalSearchParam, e);
            setSearchParams(searchParams, {
              replace: true,
            });
          }
        }}
        placeholder={placeholder}
        paddingLeft="36px"
        {...props}
      />
    </InputGroup>
  );
};
