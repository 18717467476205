import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Td,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";

import { Modal } from "components/Dialog";
import { SearchInput } from "components/Filters/SearchInput";
import { Table } from "components/Table";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { Recipient } from "modules/notifications/application/Recipient";
import { useRecipientsQuery } from "modules/notifications/infrastructure/useRecipientsQuery";
import { TariffTypes } from "modules/tariffs/application/types/ITariff";

interface IProps {
  id: string;
}

export const RecipientPreviewModal = ({ id }: IProps) => {
  return (
    <Modal<TariffTypes[]> id={id} size="6xl">
      {({ payload, onClose }) => {
        if (!payload) {
          return null;
        }

        return (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <HStack pr={6}>
                  <Text>{t("Adresaci")}</Text>
                  <Spacer />
                  <SearchInput globalSearchParam="recipients" />
                </HStack>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody as={VStack} align="stretch" spacing={6}>
                <RecipientTable tariffTypes={payload} />
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>{t("Anuluj")}</Button>
              </ModalFooter>
            </ModalContent>
          </>
        );
      }}
    </Modal>
  );
};

interface IRecipientTableProps {
  tariffTypes: TariffTypes[];
}

const RecipientTable = ({ tariffTypes }: IRecipientTableProps) => {
  const { data, isLoading } = useRecipientsQuery(tariffTypes);

  if (isLoading) {
    return <TableLoadingState />;
  }

  return (
    <Table<Recipient>
      data={data.recipients}
      columns={columns}
      globalSearchParam="recipients"
    />
  );
};

const columnHelper = createColumnHelper<Recipient>();

const columns = [
  columnHelper.accessor("organizationName", {
    header: () => t("Podmiot"),
    enableSorting: false,
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("name", {
    header: () => t("Nazwa konta"),
    enableSorting: false,
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("email", {
    header: () => t("E-mail"),
    enableSorting: false,
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("tariffType", {
    header: () => t("Pakiet"),
    enableSorting: false,
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
];
