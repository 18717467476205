import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  HStack,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { formatNumber } from "utils/i18n/formatNumber";
import { relativeTime } from "utils/i18n/relativeTime";

import { CircularProgress } from "components/CircularProgress";
import { FormatDate } from "components/FormatDate";
import { RocketIcon } from "components/icons/RocketIcon";

import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { ITariff } from "modules/tariffs/application/types/ITariff";

interface IProps {
  isLoading: boolean;
  tariff: ITariff;
}

const TariffUsageConnected = ({ isLoading, tariff }: IProps) => {
  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="78px" />
      </Stack>
    );
  }

  const totalCount =
    tariff.usageLimit.apiVerificationCount +
    tariff.usageLimit.defaultVerificationCount;

  const percentageUsage = (totalCount / tariff.usageLimit.totalLimit!) * 100;

  const isUnlimited = tariff.usageLimit.type === "UNLIMITED";

  return (
    <HStack spacing={4}>
      {isUnlimited ? (
        <RocketIcon h="78px" w="78px" p={3} color="purple.500" />
      ) : (
        <CircularProgress percentage={percentageUsage} size="78px" />
      )}
      <VStack spacing="0" align="stretch" fontSize="sm">
        <Text fontWeight="600" fontSize="lg">
          {t("Pakiet {{size}}", { size: tariff.type })}
        </Text>
        {isUnlimited ? (
          <Text textColor="gray.600">
            {t("Weryfikacje wykonane w ramach pakietu: {{totalCount}}", {
              totalCount: formatNumber(totalCount),
            })}
          </Text>
        ) : (
          <Text textColor="gray.600">
            {t("Wykonano {{totalCount}} z {{limit}} dostępnych weryfikacji", {
              totalCount: formatNumber(totalCount),
              limit: formatNumber(tariff.usageLimit.totalLimit!),
            })}
          </Text>
        )}
        <Text textColor="gray.600">
          {t("Ważny do")} <FormatDate date={tariff.validTo} />{" "}
          {relativeTime(tariff.validTo)}
        </Text>
      </VStack>
    </HStack>
  );
};

export const TariffUsageWidget = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const { data, isLoading } = useOrganizationByIdQuery(organizationId!);

  if (data?.organization?.tariffs.current === null) return null;

  return (
    <Card>
      <CardBody py="3" px="6">
        <TariffUsageConnected
          isLoading={isLoading}
          tariff={data?.organization?.tariffs.current}
        />
      </CardBody>
    </Card>
  );
};
