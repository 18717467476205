import { IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "i18next";

import { useOpenDialog } from "components/Dialog";
import { CancelIcon } from "components/icons/CancelIcon";

import { RemoveFromBlackListConfirmation } from "./RemoveFromBlackListConfirmation";

interface IProps {
  organizationId: string;
  organizationName: string;
}

export const RemoveFromBlackListBtn = ({
  organizationId,
  organizationName,
}: IProps) => {
  const removeId = `remove-from-black-list-${organizationId}`;
  const onOpenRemoveFromBlackList = useOpenDialog(removeId);

  return (
    <>
      <RemoveFromBlackListConfirmation
        id={removeId}
        organizationId={organizationId!}
        organizationName={organizationName}
      />
      <Tooltip label={t("Usuń z czarnej listy")}>
        <IconButton
          variant="link"
          colorScheme="purple"
          size="sm"
          data-testid="remove-from-black-list"
          onClick={onOpenRemoveFromBlackList}
          aria-label={"Usuń z czarnej listy"}
          icon={<CancelIcon />}
        />
      </Tooltip>
    </>
  );
};
