import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useMarkAllVerificationAsRead = (contextId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["pendingVerifications", "markAllAsRead"],
    mutationFn: () => client.post(`contexts/${contextId}/markAllAsRead`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingVerifications"],
      });
    },
  });
};
