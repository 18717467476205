import { ReactNode } from "react";

import {
  Box,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { t } from "i18next";

import { useLocalStorage } from "utils/useLocalStorage";

import { useChangelogQuery } from "modules/changelog/infrastructure/useChangelogQuery";
import { getTrackedChange } from "modules/dashboard/application/getTrackedChange";

interface IProps extends Omit<BoxProps, "children"> {
  children: (Function?: () => void) => ReactNode;
  save: () => void;
}

const NotReadDotConnected = ({ children, save, ...props }: IProps) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Box position="relative">
      <Box
        position="absolute"
        top="10px"
        right="2"
        height="10px"
        width="10px"
        bg="red"
        rounded="full"
        zIndex="2"
        data-testid="not-read-dot"
        {...props}
      />
      <Popover isOpen={isOpen} onClose={onClose} closeOnBlur={true}>
        <PopoverTrigger>
          {children(() => {
            save();
          })}
        </PopoverTrigger>
        <PopoverContent
          bgColor="purple.100"
          display={{ base: "none", lg: isOpen ? "block" : "none" }}
        >
          <PopoverArrow bgColor="purple.100" />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold" border="0" fontSize="1rem">
            {t("Co nowego?")}
          </PopoverHeader>
          <PopoverBody>
            {t("Zobacz zmiany wprowadzone w najnowszej aktualizacji systemu.")}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const NotReadDot = (props: Omit<IProps, "save">) => {
  const { data, isLoading } = useChangelogQuery();
  const [latestSeenChange, setValue] = useLocalStorage("latestSeenChange");

  if (isLoading) {
    return props.children();
  }

  const publishedChanges = data.changelog.filter(
    ({ publishedAt }) => publishedAt && dayjs(publishedAt).isBefore(dayjs())
  );

  const latestUpdate = getTrackedChange(publishedChanges);

  if (!latestUpdate) {
    return props.children();
  }

  if (
    latestSeenChange &&
    !dayjs(latestSeenChange).isBefore(dayjs(latestUpdate.publishedAt!))
  ) {
    return props.children();
  }

  return (
    <NotReadDotConnected
      {...props}
      save={() => {
        setValue(latestUpdate.publishedAt!);
      }}
    />
  );
};
