import { Button } from "@chakra-ui/react";
import { t } from "i18next";

import { TestIcon } from "components/icons/TestIcon";

import { useVerifyWorker } from "modules/workerIntegration/infrastructure/useVerifyWorker";
import { useWorkersQuery } from "modules/workerIntegration/infrastructure/useWorkersQuery";

export const TestAllWorkersButton = () => {
  const { data, isLoading } = useWorkersQuery();
  const { mutateAsync: testKey, isPending } = useVerifyWorker();

  if (isLoading) {
    return null;
  }

  const handleClick = async () => {
    await Promise.all(
      data?.organizations.map((org) => {
        return testKey(org.organizationId);
      })
    );
  };

  return (
    <Button
      leftIcon={<TestIcon />}
      variant="solid"
      colorScheme="purple"
      size="sm"
      data-testid="test-all-workers"
      onClick={handleClick}
      flexShrink={0}
      title={t("Testuj wszystkie")}
      isLoading={isPending}
    >
      {t("Testuj wszystkie")}
    </Button>
  );
};
