import { useNavigate } from "react-router-dom";

import {
  Button,
  Flex,
  GridItem,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import { CancelIcon } from "components/icons/CancelIcon";

import { useBlackListQuery } from "modules/organization/infrastructure/useBlackListQuery";
import { useOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";

import { DashboardTile } from "../DashboardTile";

export const BlackListTile = () => {
  const { data, isLoading } = useBlackListQuery({ active: true });
  const { data: organizationsData, isPending } = useOrganizationsQuery();
  const navigate = useNavigate();

  if (isLoading || isPending) {
    return (
      <>
        <GridItem colSpan={2}>
          <Skeleton height="100%" />
        </GridItem>
      </>
    );
  }

  const bannedOrganizations = organizationsData?.organizations.filter(
    (org) => org.bannedDate
  );

  const paymentDue = data?.blackList.filter((process) => !process.canceled);

  return (
    <DashboardTile icon={<CancelIcon />} title={t("Czarna lista")}>
      <VStack align="stretch" gap="8" h="100%">
        <SimpleGrid columns={{ base: 1, sm: 2 }} gap="4">
          <Flex flexDir="column" alignItems="center" py={2}>
            <CancelIcon size="24px" mb="2" color="red.500" />
            <Text fontSize="lg" fontWeight="semibold">
              {bannedOrganizations.length}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {t("Zablokowane podmioty")}
            </Text>
          </Flex>
          <Flex flexDir="column" alignItems="center" py={2}>
            <CancelIcon size="24px" mb="2" color="yellow.500" />
            <Text fontSize="lg" fontWeight="semibold">
              {paymentDue.length}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {t("Wezwane do zapłaty")}
            </Text>
          </Flex>
        </SimpleGrid>
        <Button
          mt="auto"
          colorScheme="purple"
          onClick={() => navigate("/czarna-lista")}
          variant="outline"
        >
          {t("Przeglądaj czarną listę")}
        </Button>
      </VStack>
    </DashboardTile>
  );
};
