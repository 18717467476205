import { IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { useIsMutating } from "@tanstack/react-query";
import { t } from "i18next";
import { HTTPError } from "ky";

import { toastWithError } from "components/ErrorHandling/toastWithError";
import { TestIcon } from "components/icons/TestIcon";

import { useVerifyWorker } from "modules/workerIntegration/infrastructure/useVerifyWorker";

interface IProps {
  organizationId: string;
}

export const TestWorkerButton = ({ organizationId }: IProps) => {
  const { mutateAsync: testKey } = useVerifyWorker();
  const isMutating = useIsMutating({
    mutationKey: ["test-api-key"],
  });

  const toast = useToast({ variant: "subtle" });
  const label = t("Testuj worker");

  return (
    <Tooltip label={label} aria-label={label}>
      <IconButton
        variant="link"
        colorScheme="purple"
        size="sm"
        data-testid="verify-worker"
        isLoading={!!isMutating}
        onClick={async () => {
          try {
            await testKey(organizationId);
            toast({
              description: t("Wysłano zapytanie do serwera"),
              status: "success",
            });
          } catch (error) {
            if (error instanceof HTTPError) {
              const errorJson = await error.response.json<{
                message: string;
              }>();

              if (errorJson.message) {
                return toast({
                  description: errorJson.message,
                  status: "error",
                });
              }
            }
            toastWithError({
              error,
            });
          }
        }}
        aria-label={label}
        icon={<TestIcon />}
      />
    </Tooltip>
  );
};
