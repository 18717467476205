import { useMutation, useQueryClient } from "@tanstack/react-query";

import { client } from "utils/http";

export const useRemoveFromBlackList = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["remove-from-black-list"],
    mutationFn: () => {
      return client.delete(`organizations/${organizationId}/blackList`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organizations", organizationId, "details"],
      });
      queryClient.invalidateQueries({
        queryKey: ["blackList", "active"],
      });
      queryClient.invalidateQueries({
        queryKey: ["blackList"],
      });
    },
  });
};
