import { useMutation } from "@tanstack/react-query";

import { client } from "utils/http";

export const useVerifyWorker = () => {
  return useMutation({
    mutationKey: ["verify-worker"],
    mutationFn: (organizationId: string) => {
      return client.post(`workerIntegration/verify/${organizationId}`);
    },
  });
};
