import { QueryClient } from "@tanstack/react-query";
import { HttpResponse, http } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IBlackListProcess } from "../application/types/IBlackListProcess";

const activeQuery = () => {
  return {
    queryKey: ["blackList", "active"],
    queryFn: () =>
      client.get<{ blackList: IBlackListProcess[] }>(`blackList?active=true`),
  };
};

const query = () => {
  return {
    queryKey: ["blackList"],
    queryFn: () => client.get<{ blackList: IBlackListProcess[] }>(`blackList`),
  };
};

export const getBlackListQuery = (queryClient: QueryClient) => {
  return queryClient.ensureQueryData(query());
};

export const useBlackListQuery = ({ active }: { active: boolean }) => {
  return useQuery(active ? activeQuery() : query());
};

export const getBlackListMock = (blackList: IBlackListProcess[]) => {
  return http.get(withHost("blackList"), () => {
    return HttpResponse.json<{ blackList: IBlackListProcess[] }>({
      blackList: blackList,
    });
  });
};
