import { withTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";

import { HStack, SimpleGrid, VStack } from "@chakra-ui/react";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { BlackListAlert } from "modules/organization/presentation/BlackListAlert";
import { OrganizationPreviewWidget } from "modules/organization/presentation/OrganizationPreviewWidget";
import { TariffUsageWidget } from "modules/tariffs/presentation/TariffUsageWidget";
import { StartVerification } from "modules/verification/presentation/StartVerificationV2";

export const Verification = withTranslation()(() => {
  const { userInfo } = ContextMachineContext.useSelector(
    (state) => state.context
  );
  const { organizationId } = useParams<{ organizationId: string }>();
  const { data } = useOrganizationByIdQuery(organizationId!);

  if (userInfo?.currentlySelectedContext?.type !== "context_user") {
    return <Navigate to={`/`} />;
  }

  return (
    <>
      <VStack align="stretch" gap="8">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="8">
          <OrganizationPreviewWidget />
          <TariffUsageWidget />
        </SimpleGrid>
        <HStack gap="8" align="start">
          <VStack align="stretch" w="100%" gap="6">
            <BlackListAlert />
            {!data?.organization.bannedDate && <StartVerification />}
          </VStack>
        </HStack>
      </VStack>
    </>
  );
});
