import { withTranslation } from "react-i18next";

import { Card, CardBody, Heading, HStack, VStack } from "@chakra-ui/react";
import { t } from "i18next";

import { BlackListedOrganizationsTable } from "modules/organization/presentation/BlackListedOrganizationsTable";

export const BlackList = withTranslation()(() => {
  return (
    <VStack align="stretch" gap="8">
      <Card>
        <CardBody px={4} py={6}>
          <Heading fontSize="lg">{t("Czarna lista")}</Heading>
        </CardBody>
      </Card>
      <HStack gap="8" align="start">
        <VStack align="stretch" w="100%" gap="6">
          <BlackListedOrganizationsTable />
        </VStack>
      </HStack>
    </VStack>
  );
});
