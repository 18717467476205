import { useSearchParams } from "react-router-dom";

import {
  Text,
  VStack,
  CheckboxGroup as CCheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";

interface IProps<T extends string> {
  label: string;
  filterId: string;
  options: [T, string][];
}

export const CheckboxGroup = <T extends string>({
  filterId,
  label,
  options,
}: IProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value =
    searchParams
      .get(filterId)
      ?.split(",")
      .filter((val) => val) || [];

  return (
    <VStack align="stretch" spacing={2}>
      <Text fontWeight="600">{label}</Text>
      <CCheckboxGroup
        colorScheme="purple"
        onChange={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          searchParams.set(filterId, e as any);
          setSearchParams(searchParams, {
            replace: true,
          });
        }}
        value={value}
      >
        <VStack align="stretch">
          {options.map(([value, label]) => (
            <Checkbox value={value} key={value}>
              {label}
            </Checkbox>
          ))}
        </VStack>
      </CCheckboxGroup>
    </VStack>
  );
};
