import dayjs from "dayjs";
import { t } from "i18next";

export const relativeTime = (date: string) => {
  const diff = dayjs(date).diff(dayjs(), "day");

  if (diff < 0) {
    return t("zakończono {{value, relativetime}}", {
      value: diff,
    });
  }

  if (diff === 0) {
    const diff = dayjs(date).diff(dayjs(), "hours");

    if (diff < 0) {
      return t("zakończono {{value, relativetime(hours)}}", {
        value: Math.abs(diff),
      });
    }

    return t("upływa {{value, relativetime(hours)}}", {
      value: diff,
    });
  }

  return t("(upływa {{value, relativetime}})", {
    value: diff,
  });
};
