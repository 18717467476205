import { QueryClient } from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { client, withHost } from "utils/http";
import { useQuery } from "utils/useQuery";

import { IWorker } from "../application/types/IWorker";

const query = () => {
  return {
    queryKey: ["workers"],
    queryFn: () =>
      client.get<{ organizations: IWorker[] }>(
        `workerIntegration/organizations`
      ),
  };
};

export const getWorkersQuery = (queryClient: QueryClient) => {
  return queryClient.ensureQueryData(query());
};

export const useWorkersQuery = () => {
  return useQuery(query());
};

export const getWorkersMock = (organizations: IWorker[] = []) => {
  return http.get(withHost("workerIntegration/organizations"), () => {
    return HttpResponse.json<{ organizations: IWorker[] }>({
      organizations: [...organizations],
    });
  });
};
