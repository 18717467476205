import { useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  Td,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

import { SearchInput } from "components/Filters/SearchInput";
import { FormatDateTime } from "components/FormatDateTime";
import { Table } from "components/Table";
import { TableEmptyState } from "components/Table/TableEmptyState";
import { TableLoadingState } from "components/Table/TableLoadingState";

import { NotificationsEmails } from "modules/notifications/application/NotificationsEmails";
import { useNotificationProcessEmailsQuery } from "modules/notifications/infrastructure/useNotificationProcessEmailsQuery";

import { EmailStatusBadge } from "./EmailStatusBadge";

const columnHelper = createColumnHelper<NotificationsEmails>();

const columns = [
  columnHelper.accessor("organizationName", {
    header: () => t("Podmiot"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("name", {
    header: () => t("Nazwa konta"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("email", {
    header: () => t("E-mail"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("tariffType", {
    header: () => t("Pakiet"),
    cell: (info) => <Td whiteSpace="normal">{info.getValue()}</Td>,
  }),
  columnHelper.accessor("sentAt", {
    header: () => t("Data wysyłki"),
    cell: (info) => (
      <Td whiteSpace="normal">
        {info.getValue() ? <FormatDateTime date={info.getValue()!} /> : "---"}
      </Td>
    ),
  }),
  columnHelper.accessor("sent", {
    header: () => t("Status"),
    cell: (info) => (
      <Td whiteSpace="normal">
        <EmailStatusBadge
          sent={info.getValue()}
          failed={!!info.row.original.failReason}
        />
      </Td>
    ),
  }),
];

interface IProps {
  emails: NotificationsEmails[];
  isLoading: boolean;
}

const NotificationEmailsTableConnected = ({ emails, isLoading }: IProps) => {
  if (isLoading) {
    return <TableLoadingState />;
  }

  if (emails.length === 0) {
    return <TableEmptyState title={t("Brak wiadomości")} />;
  }

  const sortedRecords = reverse(
    sortBy(emails, function (data) {
      return new Date(data.sentAt!);
    })
  );

  return <Table<NotificationsEmails> data={sortedRecords} columns={columns} />;
};

export const NotificationEmailsTable = () => {
  const { notificationProcessId } = useParams<{
    notificationProcessId: string;
  }>();

  const { data, isLoading } = useNotificationProcessEmailsQuery(
    notificationProcessId!
  );

  return (
    <Card id="users" variant="sawpe">
      <CardHeader>
        <Heading fontSize="lg">{t("Wysłane wiadomości")}</Heading>
        <Spacer />
        <SearchInput />
      </CardHeader>
      <CardBody>
        <NotificationEmailsTableConnected
          emails={data?.emails}
          isLoading={isLoading}
        />
      </CardBody>
    </Card>
  );
};
