import { Navigate, Outlet, RouteObject } from "react-router-dom";

import { QueryClient } from "@tanstack/react-query";
import ApiDocumentation from "Pages/ApiDocumentation";
import { BlackList } from "Pages/BlackList";
import { Changelog } from "Pages/Changelog";
import { ContextManagers } from "Pages/ContextManagers";
import { EventHistory } from "Pages/EventHistory";
import { ForgotPassword } from "Pages/ForgotPassword";
import { Login } from "Pages/Login";
import { Notification } from "Pages/Notification";
import { Notifications } from "Pages/Notifications";
import { OrganizationConfig } from "Pages/OrganizationConfig";
import { Register } from "Pages/Register";
import { ResetPassword } from "Pages/ResetPassword";
import { Start } from "Pages/Start";
import { Organizations } from "Pages/Start/Organizations";
import { SystemEvents } from "Pages/SystemEvents";
import { Verification } from "Pages/Verification";
import { VerificationsHistory } from "Pages/VerificationsHistory";

import { ErrorState } from "components/EmptyStates/ErrorState";
import { PublicLayout } from "components/Layout/PublicLayout";

import { getApiKeysQuery } from "modules/apiKey/infrastructure/useApiKeysQuery";
import { ContextType } from "modules/auth/application/types/IContext";
import { getChangelogQuery } from "modules/changelog/infrastructure/useChangelogQuery";
import { getEventsQuery } from "modules/eventLog/infrastructure/useEventsQuery";
import { getSystemEventsQuery } from "modules/eventLog/infrastructure/useSystemEventsQuery";
import { getNotificationProcessByIdQuery } from "modules/notifications/infrastructure/useNotificationProcessByIdQuery";
import { getNotificationProcessEmailsQuery } from "modules/notifications/infrastructure/useNotificationProcessEmailsQuery";
import { getNotificationProcessQuery } from "modules/notifications/infrastructure/useNotificationProcessQuery";
import { getBlackListQuery } from "modules/organization/infrastructure/useBlackListQuery";
import { getContextsManagersQuery } from "modules/organization/infrastructure/useContextsManagersQuery";
import { getOrganizationByIdQuery } from "modules/organization/infrastructure/useOrganizationByIdQuery";
import { getOrganizationContextsQuery } from "modules/organization/infrastructure/useOrganizationContextsQuery";
import { getOrganizationsQuery } from "modules/organization/infrastructure/useOrganizationsQuery";
import { getSystemContextsQuery } from "modules/system/infrastructure/useSystemContextsQuery";
import { getAllTariffsExpirationsQuery } from "modules/tariffs/infrastructure/useAllTariffsExpirationsQuery";
import { getTariffsExpirationsQuery } from "modules/tariffs/infrastructure/useTariffsExpirationsQuery";
import { getTariffsQuery } from "modules/tariffs/infrastructure/useTariffsQuery";
import { getContextVerificationsHistoryQuery } from "modules/verification/infrastructure/useContextVerificationsHistoryQuery";
import { getOrganizationVerificationInterpretationsQuery } from "modules/verification/infrastructure/useOrganizationVerificationInterpretationsQuery";
import { getPendingVerificationsQuery } from "modules/verification/infrastructure/usePendingVerificationsQuery";
import { getVerificationInterpretationsQuery } from "modules/verification/infrastructure/useVerificationInterpretationsQuery";
import { getVerificationsHistoryQuery } from "modules/verification/infrastructure/useVerificationsHistoryQuery";
import { getWorkersQuery } from "modules/workerIntegration/infrastructure/useWorkersQuery";

import App from "./App";

export const publicRouter = (): RouteObject[] => [
  {
    path: "/",
    element: (
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    ),
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "/zapomnialem-hasla",
        element: <ForgotPassword />,
      },
      {
        path: "/zresetuj-haslo",
        element: <ResetPassword />,
      },
      {
        path: "/rejestracja",
        element: <Register />,
      },
      {
        path: "/*",
        element: <Navigate to={"/"} />,
      },
    ],
  },
];

export const router = (
  queryClient: QueryClient,
  contextType: ContextType,
  contextId: string
): RouteObject[] => [
  {
    path: "/zresetuj-haslo",
    element: (
      <PublicLayout>
        <ResetPassword />
      </PublicLayout>
    ),
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorState />,
    children: [
      {
        index: true,
        element: <Start />,
        loader: async () => {
          switch (contextType) {
            case "system_admin":
              return pendingTimeout(() =>
                Promise.all([
                  getWorkersQuery(queryClient),
                  getOrganizationsQuery(queryClient),
                  getSystemContextsQuery(queryClient),
                ])
              );
            case "system_manager":
              return pendingTimeout(() =>
                Promise.all([
                  getOrganizationsQuery(queryClient),
                  getNotificationProcessQuery(queryClient),
                  getAllTariffsExpirationsQuery(queryClient),
                ])
              );
            case "context_manager":
              return {};
            case "context_user":
              return {};
            default:
              return {};
          }
        },
      },
      {
        path: "/organizacje",
        element: <Organizations />,
        loader: async () => {
          return pendingTimeout(() =>
            Promise.all([
              getOrganizationsQuery(queryClient),
              getVerificationInterpretationsQuery(queryClient),
            ])
          );
        },
      },
      {
        path: "/powiadomienia",
        element: <Notifications />,
        loader: async () => {
          return pendingTimeout(() =>
            Promise.all([getNotificationProcessQuery(queryClient)])
          );
        },
      },
      {
        path: "/powiadomienia/:notificationProcessId",
        element: <Notification />,
        loader: async ({ params }) => {
          return pendingTimeout(() =>
            Promise.all([
              getNotificationProcessByIdQuery(queryClient)(
                params.notificationProcessId!
              ),
              getNotificationProcessEmailsQuery(queryClient)(
                params.notificationProcessId!
              ),
            ])
          );
        },
      },
      {
        path: "/zdarzenia-systemowe",
        element: <SystemEvents />,
        loader: async () => {
          return pendingTimeout(() =>
            Promise.all([getSystemEventsQuery(queryClient)])
          );
        },
      },
      {
        path: "/changelog",
        element: <Changelog />,
        loader: async () => {
          return pendingTimeout(() =>
            Promise.all([getChangelogQuery(queryClient)])
          );
        },
      },
      {
        path: "/administratorzy",
        element: <ContextManagers />,
        loader: async () => {
          return pendingTimeout(() =>
            Promise.all([getContextsManagersQuery(queryClient)])
          );
        },
      },
      {
        path: "/czarna-lista",
        element: <BlackList />,
        loader: async () => {
          return pendingTimeout(() =>
            Promise.all([
              getOrganizationsQuery(queryClient),
              getBlackListQuery(queryClient),
            ])
          );
        },
      },
      {
        path: ":organizationId",
        element: <OrganizationConfig />,
        loader: async ({ params }) => {
          switch (contextType) {
            case "system_admin":
            case "system_manager":
            case "context_manager":
              return pendingTimeout(() =>
                Promise.all([
                  getOrganizationByIdQuery(queryClient)(params.organizationId!),
                  getOrganizationContextsQuery(queryClient)(
                    params.organizationId!
                  ),
                  getTariffsQuery(queryClient)(params.organizationId!),
                  getApiKeysQuery(queryClient)(params.organizationId!),
                  getOrganizationVerificationInterpretationsQuery(queryClient)(
                    params.organizationId!
                  ),
                  getTariffsExpirationsQuery(queryClient)(
                    params.organizationId!
                  ),
                ])
              );
            case "context_user":
              return {};
            default:
              return {};
          }
        },
      },
      {
        path: ":organizationId/api-doc",
        element: <ApiDocumentation />,
        loader: async ({ params }) => {
          return pendingTimeout(() =>
            Promise.all([getTariffsQuery(queryClient)(params.organizationId!)])
          );
        },
      },
      {
        path: ":organizationId/weryfikacja",
        element: <Verification />,
        loader: async ({ params }) => {
          return pendingTimeout(() =>
            Promise.all([
              getOrganizationByIdQuery(queryClient)(params.organizationId!),
              getPendingVerificationsQuery(queryClient)(contextId),
            ])
          );
        },
      },
      {
        path: ":organizationId/historia-weryfikacji",
        element: <VerificationsHistory />,
        loader: async ({ params }) => {
          if (contextType === "context_user") {
            return pendingTimeout(() =>
              Promise.all([
                getOrganizationByIdQuery(queryClient)(params.organizationId!),
                getPendingVerificationsQuery(queryClient)(contextId),
                getContextVerificationsHistoryQuery(queryClient)(contextId),
              ])
            );
          }

          return pendingTimeout(() =>
            Promise.all([
              getOrganizationByIdQuery(queryClient)(params.organizationId!),
              getVerificationsHistoryQuery(queryClient)(params.organizationId!),
            ])
          );
        },
      },
      {
        path: ":organizationId/historia-aktywnosci",
        element: <EventHistory />,
        loader: async ({ params }) => {
          return pendingTimeout(() =>
            Promise.all([
              getOrganizationByIdQuery(queryClient)(params.organizationId!),
              getEventsQuery(queryClient)(params.organizationId!),
            ])
          );
        },
      },
    ],
  },
];

// https://react-location.tanstack.com/guides/pending-states#pending-element-timeouts
async function pendingTimeout<T>(sourceFn: () => Promise<T>, ms: number = 350) {
  return Promise.race([
    sourceFn(),
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({});
      }, ms);
    }),
  ]);
}
