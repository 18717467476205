import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import { IVerificationResult } from "verification-widget";

import { useEventSourceConsumer } from "modules/events/application/EventSourceProvider";

import { useSearch } from "./useSearch";
import { useVerify } from "./useVerify";

interface IProps {
  files: File[];
  callback: (result: IVerificationResult) => void;
  onError: (error: unknown) => void;
}

export const useListenForVerificationStatus = (contextId: string) => {
  const currentVerificationId = useRef<string | null>(null);
  const { organizationId } = useParams<{ organizationId: string }>();
  const [verificationStatus, setVerificationStatus] = useState<
    "verifying" | "uploading" | "idle"
  >("idle");

  const { subscribe, unsubscribe } = useEventSourceConsumer();

  const queryClient = useQueryClient();
  const { mutateAsync: verify } = useVerify(contextId);
  const { mutateAsync: search } = useSearch();

  const abort = () => {
    if (currentVerificationId.current) {
      unsubscribe(currentVerificationId.current);
    }
    setVerificationStatus("idle");
  };

  const listenForVerificationStatus = async ({
    files,
    callback,
    onError,
  }: IProps) => {
    try {
      setVerificationStatus("uploading");

      const { verificationProcess } = await verify({
        files,
      });

      const id = verificationProcess.verificationId;
      currentVerificationId.current = verificationProcess.verificationId;

      setVerificationStatus("verifying");
      subscribe({
        id,
        eventType: "verification_process_update",
        onEvent: async (event) => {
          try {
            if (
              event.status === "FAILED" &&
              event.verificationId === verificationProcess.verificationId
            ) {
              abort();
              throw new Error(`Verification ${event.verificationId} failed`);
            }

            if (
              event.status === "FINISHED" &&
              event.verificationId === verificationProcess.verificationId
            ) {
              const result = await search(event.verificationId);

              queryClient.invalidateQueries({
                queryKey: ["organizations", organizationId, "details"],
              });
              abort();
              callback(result);
            }
          } catch (error) {
            abort();
            onError(error);
          }
        },
      });
    } catch (error) {
      setVerificationStatus("idle");
      onError(error);
    }
  };

  return [listenForVerificationStatus, verificationStatus, abort] as const;
};
