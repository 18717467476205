import { useEffect } from "react";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import { ContextMachineContext } from "modules/auth/application/ContextMachineContext";
import { useEventSourceConsumer } from "modules/events/application/EventSourceProvider";

export const useListenForPendingVerifications = (
  toast: CreateToastFnReturn
) => {
  const contextId = ContextMachineContext.useSelector((state) => state.context)
    .userInfo?.currentlySelectedContext?.id;
  const queryClient = useQueryClient();

  const { subscribe, unsubscribe } = useEventSourceConsumer();

  useEffect(() => {
    subscribe({
      id: "new-unread-verification",
      eventType: "verification_process_update",
      onEvent: (event) => {
        if (event.status === "FINISHED") {
          toast();
        }
      },
    });

    // Cleanup on component unmount
    return () => {
      unsubscribe("new-unread-verification");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextId, queryClient]);
};
